<template>
  <div id="pdf" style="top: 0px;left: 0px;right: 0px;bottom: 0px;background-color: white;font-size: 14px;font-family: sans-serif;overflow:scroll;">
    <div class="paper" style="width: 720px;margin: auto;">
      <table id="p-table" class="table w-full text-left whitespace-no-wrap">
        <thead class="bg-gray-200">
          <tr class="text-left">
            <th class="border p-2">{{ $t('order_id') | t }}</th>
            <th class="border p-1 text-center ">{{ $t('seller') }}</th>
            <th class="border p-2 text-center">{{ $t('customer') }}</th>
            <th class="border p-2 text-center">{{ $t('phone') }}</th>
            <th class="border p-2 text-center">{{ $t('products') }}</th>
            <th class="border p-2 text-center">{{ $t('quantity') }}</th>
            <th class="border p-2 text-center">{{ $t('totalPrice') }}</th>
            <th class="border p-2 text-center">{{ $t('address') }}</th>
            <th class="border p-2 text-center">{{ $t('order_date') }}</th>
            <th class="border p-2 text-center">{{ $t('status') }}</th>
            <th class="border p-2 text-center">{{ $t('comment') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th v-if="items.length == 0" class="border p-3 bg-gray-100" colspan="12">
              {{ "no_record_found" | t }}
            </th>
          </tr>
          <tr class="text-center" v-for="(item, index) in items" :key="index" :class="index % 2 == 0 ? 'bg-gray-100' : ''">
            <td class="border p-2">
              <span class="flex items-center">
                {{ item.id  }}
              </span>
            </td>
            <td class="border p-2">
              <span class="flex items-center">
                {{ item.seller && item.seller.fullName ? item.seller.fullName : "" }}
              </span>
            </td>
            <td class="border p-2">
              <span class="flex items-center">
                {{ item.customer && item.customer.fullName ? item.customer.fullName : "" }}
              </span>
            </td>
             <td class="border p-2">
              <span class="flex items-center">
                {{ item.customer && item.customer.phone ? item.customer.phone : "" }}
              </span>
            </td>
            <td class="border p-2  bg-green-100">
              {{ sumProduct(item.details) }}
            </td>
            <td class="border p-2  bg-green-100">
              {{ sumQteProduct(item.details) }}
            </td>
            <td class="border p-2  bg-green-100">
              {{ item.totalPrice }}
            </td>
             <td class="border p-2  bg-green-100">
              {{ item.customer && item.customer.address ? item.customer.address : "" }}
            </td>
            <td class="border p-2  bg-green-100">
              {{ $moment(item.date).format('DD-MM-YYYY') }}
            </td>
            <td class="border p-2  bg-green-100">
              {{ item.status?item.status.name:'' }}
            </td>
            <td class="border p-2  bg-green-100">
              {{ item.comment }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  mounted() {
    // const pdf = document.querySelector("#pdf").cloneNode(true);
    // document.querySelector("#app").before(pdf);
    // document.querySelector("#app").style.display = "none";
    // window.print();
    // pdf.remove();
    // document.querySelector("#app").style.display = "block";
    // this.exportToExcel()
    // this.$emit("printed");
    console.log('items excel____',this.items)
  },
  methods: {
    // getRestDays(endDate) {
    //   return this.$options.filters.toDays(endDate, Date.now());
    // },
    sumQteProduct(data){
      let sum=0;
      for (let i in data) {
        sum=sum+data[i].quantity;
      }
      return sum;
    },
    sumProduct(data){
      let product="";
      for (let i in data) {
        if(data[i].product && data[i].product.name) product+=data[i].product.name
        if(data.length-1 != i) product+=" ,"; 
      }
      return product;
    },
  }
};
</script>
<style>
table {
  page-break-inside: auto;
}
tr {
  page-break-inside: avoid;
  page-break-after: auto;
}
thead {
  display: table-header-group;
}
tfoot {
  display: table-footer-group;
}
</style>
